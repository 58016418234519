<ih-admin-base editorTitle="Url redirects" editorSvgIcon="link-plus">
  <div button-controls class="button-controls fx-layout-row fx-layout-align-start-center">
    <button class="add-redirect-btn" type="button" mat-flat-button color="primary" (click)="addRedirect()">
      Add redirect
    </button>
  </div>
  <div class="info-panel-container">
    <ih-info-panel>
      <h4>What is a URL Redirect?</h4>
      A URL redirect is a way to send both users and search engines to a different URL from the one they originally
      requested. This feature is commonly used to manage the following scenarios:
      <ul>
        <li>
          Redirecting old URLs to new ones: If you've moved a page or a resource, you can direct visitors from the old
          URL to the new location to avoid broken links and negative impacts on user experience and SEO.
        </li>
        <li>
          Maintaining multiple domains: If you own multiple domains, you can redirect visitors to your primary domain to
          consolidate traffic.
        </li>
        <li>
          Shortening long URLs: For marketing or usability purposes, longer URLs can be simplified to enhance
          readability and ease of sharing.
        </li>
      </ul>
      <h4>How to Use This Page</h4>
      Below, you can add, modify, or delete URL redirects. Ensure you specify the original URL (the old link) and the
      target URL (the new link to which traffic should be redirected). It's important to use correct URLs to prevent
      errors and ensure a smooth user experience.
      <ol>
        <li>Add a Redirect: Click on 'Add redirect' to create a new entry.</li>
        <li>
          Fill in Details: Enter the original URL and the target URL. Use the format guidelines provided as placeholders
          in the fields.
        </li>
        <li>Save Changes: After adding or modifying your redirects, click 'Save' to apply the changes.</li>
      </ol>
    </ih-info-panel>
  </div>
  <!-- URL redirects -->
  <div class="advanced-settings-group" *ngIf="urlRedirectsEnabled$ | async">
    @if (loading()) {
      <div class="loading-spinner-container fx-layout-row fx-layout-align-center-center">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    } @else {
      <div class="url-redirect-accordion">
        @if (redirectControls.length === 0) {
          <h3 class="text-center">You do not have any redirects</h3>
        }
        <mat-accordion>
          @for (redirect of redirectControls; track redirect.value.campaignRedirectId) {
            <form (ngSubmit)="save(redirect)" id="urlRedirectsForm" [formGroup]="urlRedirectsForm">
              <mat-expansion-panel #mep="matExpansionPanel" [formGroup]="redirect">
                <mat-expansion-panel-header>
                  <ng-container [formGroup]="redirect">
                    <mat-panel-title>
                      Redirect:&nbsp;
                      @if (redirect.controls.originalUrl.value) {
                        {{ (appOrigin$ | async) + redirect.controls.originalUrl.value }}
                      } @else {
                        {{ '/a-relative-url' }}
                      }
                    </mat-panel-title>
                  </ng-container>
                </mat-expansion-panel-header>

                <div class="redirect-editor fx-layout-column">
                  <mat-form-field class="keyword-form-field">
                    <mat-label class="keyword-label">Original URL</mat-label>
                    <ng-container [formGroup]="redirect">
                      <input
                        class="keyword-textarea"
                        matInput
                        formControlName="originalUrl"
                        placeholder="/a-relative-url"
                        (blur)="checkUrl(redirect)"
                      />
                    </ng-container>
                    <mat-hint class="keyword-hint-text" align="start"
                      >Enter the relative path that needs redirection. E.g., '/old-page'. Do not include the
                      domain.</mat-hint
                    >
                    @if (redirectControls[$index].controls['originalUrl'].hasError('required')) {
                      <mat-error> Original URL is required </mat-error>
                    }
                  </mat-form-field>

                  <mat-form-field class="keyword-form-field">
                    <mat-label class="keyword-label">Target URL</mat-label>
                    <ng-container [formGroup]="redirect">
                      <input
                        class="keyword-textarea"
                        matInput
                        formControlName="redirectTo"
                        placeholder="https://www.example.com/new-page or /new-page"
                      />
                    </ng-container>
                    <mat-hint class="keyword-hint-text" align="start"
                      >Enter the full URL where the traffic should be redirected.</mat-hint
                    >
                    @if (redirectControls[$index].controls['redirectTo'].hasError('required')) {
                      <mat-error> Target URL is required </mat-error>
                    }
                    @if (redirectControls[$index].controls['redirectTo'].hasError('invalidUrl')) {
                      <mat-error> URL is invalid </mat-error>
                    }
                  </mat-form-field>
                </div>

                <mat-action-row class="fx-layout-row">
                  <button mat-button type="button" color="warn" (click)="deleteRedirect(redirect)">Delete</button>
                  <ih-animated-save-button mat-button color="primary" [saving]="(saving$ | async) || false">
                    <span savingText>
                      @if (redirect.controls.campaignRedirectId.value) {
                        Updating
                      } @else {
                        Adding
                      }
                    </span>
                    @if (redirect.controls.campaignRedirectId.value) {
                      Update
                    } @else {
                      Add
                    }
                  </ih-animated-save-button>
                </mat-action-row>
              </mat-expansion-panel>
            </form>
          }
        </mat-accordion>
      </div>
    }
  </div>
</ih-admin-base>
