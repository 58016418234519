import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  QueryList,
  ViewChildren,
  inject,
  signal
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AdminBaseComponent } from '@ih/admin-base';
import { AnimatedSaveButtonComponent } from '@ih/animated-save-button';
import { ConfirmDialogService } from '@ih/confirm';
import { ABSOLUTE_URL_REGEX, RELATIVE_URL_REGEX } from '@ih/constants';
import { SaveChangesDialogService } from '@ih/dialogs';
import { Permission } from '@ih/enums';
import { InfoPanelComponent } from '@ih/info-panel';
import { AppConfig, Redirect } from '@ih/interfaces';
import { ConfigService, LazySnackBarService, SecurityService } from '@ih/services';
import { BehaviorSubject, catchError, of, switchMap, take, tap } from 'rxjs';

declare type RedirectGroup = {
  campaignRedirectId: FormControl<number | null>;
  originalUrl: FormControl<string | null>;
  redirectTo: FormControl<string | null>;
};

@Component({
  selector: 'ih-url-redirects',
  templateUrl: './url-redirects.component.html',
  styleUrls: ['./url-redirects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AdminBaseComponent,
    NgIf,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    AnimatedSaveButtonComponent,
    NgFor,
    AsyncPipe,
    InfoPanelComponent
  ]
})
export class UrlRedirectsComponent {
  @HostBinding('class.ih-url-redirects') hostClass = true;

  @ViewChildren('mep') mep!: QueryList<MatExpansionPanel>;

  private http = inject(HttpClient);
  private security = inject(SecurityService);
  private snackbar = inject(LazySnackBarService);
  private config = inject(ConfigService<AppConfig>);
  private saveChangesDialog = inject(SaveChangesDialogService);
  private confirmDialog = inject(ConfirmDialogService);
  private cd = inject(ChangeDetectorRef);

  urlRedirectsForm = new FormGroup({
    redirects: new FormArray([] as FormGroup<RedirectGroup>[])
  });

  isOwner: boolean = false;

  hasChanges = false;
  loading = signal(true);
  saving$ = new BehaviorSubject<boolean>(false);

  submitted$ = new BehaviorSubject<boolean>(false);

  appOrigin$ = this.config.appOrigin$;

  get redirectsArray(): FormArray {
    return this.urlRedirectsForm.get('redirects') as FormArray;
  }

  get redirectControls(): FormGroup<RedirectGroup>[] {
    return this.redirectsArray.controls as FormGroup<RedirectGroup>[];
  }

  urlRedirectsEnabled$ = this.config.config$.pipe(
    switchMap((appConfig) => of(appConfig.menuOptions?.urlRedirectsEnabled))
  );

  constructor() {
    this.saveChangesDialog.pageTitle = 'url redirects';

    if (!this.security.can(Permission.ManageAppSettings)) {
      this.snackbar.noPermission();
      return;
    }

    this.config.config$.pipe(take(1)).subscribe((appConfig) => {
      if (appConfig.menuOptions && !appConfig.menuOptions.urlRedirectsEnabled) {
        this.snackbar.noPermission();
      }
    });

    this.http.get<Redirect[]>('/api/campaign/mine/settings/redirects').subscribe((redirects) => {
      this.loading.set(false);
      this.urlRedirectsForm.setControl(
        'redirects',
        new FormArray<FormGroup<RedirectGroup>>(
          redirects.map(
            (redirect) =>
              new FormGroup<RedirectGroup>({
                campaignRedirectId: new FormControl(redirect.campaignRedirectId),
                originalUrl: new FormControl(redirect.originalUrl, [Validators.required]),
                redirectTo: new FormControl(redirect.redirectTo, [Validators.required, this.isValidUrl()])
              })
          )
        )
      );
      this.cd.markForCheck();
    });
  }

  setModified(): void {
    this.saveChangesDialog.setModified();
  }

  save(redirectGroup: FormGroup<RedirectGroup>): void {
    this.submitted$.next(true);
    this.hasChanges = true;

    const redirect = redirectGroup.value;

    if (redirectGroup.valid) {
      this.saving$.next(true);
      this.cd.markForCheck();

      if (redirect.campaignRedirectId) {
        // if the redirect has an id, it's an existing redirect
        this.http
          .put(`/api/campaign/mine/settings/redirects/${redirect.campaignRedirectId}`, redirect)
          .pipe(
            catchError((err) => {
              this.saving$.next(false);
              this.snackbar.open('Sorry. We were unable to save your changes');

              throw err;
            }),
            tap(() => {
              this.saving$.next(false);
              this.saveChangesDialog.setModified(false);
              this.snackbar.open('Redirect updated successfully');
            })
          )
          .subscribe();
      } else {
        // if the redirect does not have an id, it's a new redirect
        this.http
          .post<Redirect>('/api/campaign/mine/settings/redirects', redirect)
          .pipe(
            catchError((err) => {
              this.saving$.next(false);
              this.snackbar.open('Sorry. We were unable to save your changes');
              throw err;
            }),
            tap((newRedirect) => {
              // replace the new redirect with the one returned from the server
              const index = this.redirectControls.findIndex((r) => r.value === redirect);
              this.redirectsArray.setControl(
                index,
                new FormGroup<RedirectGroup>({
                  campaignRedirectId: new FormControl(newRedirect.campaignRedirectId),
                  originalUrl: new FormControl(newRedirect.originalUrl, [Validators.required]),
                  redirectTo: new FormControl(newRedirect.redirectTo, [Validators.required, this.isValidUrl()])
                })
              );
              this.saving$.next(false);
              this.saveChangesDialog.setModified(false);
              this.snackbar.open('Redirect added successfully');
            })
          )
          .subscribe();
      }
    }
  }

  addRedirect(): void {
    this.urlRedirectsForm.setControl(
      'redirects',
      new FormArray([
        ...(this.redirectsArray.controls as FormGroup<RedirectGroup>[]),
        new FormGroup<RedirectGroup>({
          campaignRedirectId: new FormControl(null),
          originalUrl: new FormControl('', [Validators.required]),
          redirectTo: new FormControl('', [Validators.required, this.isValidUrl()])
        })
      ])
    );
    this.cd.markForCheck();
    requestAnimationFrame(() => {
      this.mep.forEach((m) => (m.expanded = false));
      this.mep.last.expanded = true;
    });
  }

  isValidUrl(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      if (!ABSOLUTE_URL_REGEX.test(control.value) && !RELATIVE_URL_REGEX.test(control.value)) {
        return { invalidUrl: true };
      }

      return null;
    };
  }

  checkUrl(redirect: FormGroup<RedirectGroup>): void {
    if (!redirect.value.originalUrl) {
      return;
    }

    if (!redirect.value.originalUrl.startsWith('/')) {
      // make sure the url starts with a slash
      redirect.patchValue({ originalUrl: '/' + redirect.value.originalUrl });
    }
  }

  async deleteRedirect(redirect: FormGroup<RedirectGroup>): Promise<void> {
    const dialog = await this.confirmDialog.open({
      title: 'Delete Redirect',
      message: 'Are you sure you want to delete this redirect?',
      confirmText: 'Delete'
    });

    dialog.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      if (redirect.value.campaignRedirectId) {
        this.http.delete(`/api/campaign/mine/settings/redirects/${redirect.value.campaignRedirectId}`).subscribe(() => {
          this.snackbar.open('Redirect deleted successfully');
        });
      } else {
        this.snackbar.open('Redirect deleted successfully');
      }
      const index = this.redirectControls.indexOf(redirect);
      this.redirectsArray.removeAt(index);
      this.cd.markForCheck();
    });
  }
}
